<template>
  <div class="nav">
    <div class="killlog" v-show="isShow">
      <img @click="close" src="@/assets/images/bigkill/close.png" alt />
      <div class="kill">
        <div class="top"></div>
        <div class="main d-flex j-a">
          <div
            class="item color-white"
            v-for="(item, index) in historicalRecord"
            :key="index"
          >
            {{ item.roomId }}
            <div class="text">第{{ item.id }}期</div>
          </div>
        </div>
      </div>
      <div class="mylog">
        <div class="top"></div>
        <div class="main d-flex j-a">
          <div class="item" v-for="(item, index) in myhistroy" :key="index">
            <div class="one">
              <div class="big d-flex color-white justify">
                <div class="l d-flex">
                  <div class="p1">第{{ item.brId }}期</div>
                  <!-- <div class="p2 f-13">{{item.participationTime}}</div> -->
                </div>
                <div class="r color-g" v-show="item.result == '1'">
                  躲避成功
                </div>
                <div class="r" v-show="item.result == '0'">躲避失败</div>
              </div>
            </div>
            <div class="two d-flex color-white j-a">
              <div class="l">杀手去:{{ item.killRoom }}</div>
              <div class="r">自己去:{{ item.roomId }}</div>
            </div>
            <div class="three d-flex color-white j-a">
              <div class="l">投入：{{ item.participationPrice }}</div>
              <div v-if="item.result == '1'" class="r">
                获得：{{ item.participationPrice + item.coinChange }}
              </div>
              <div v-else class="r">获得：{{ item.coinChange }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="result" v-show="isResult">
      <div class="win" v-show="isWin">
        <img src="@/assets/images/bigkill/win.png" alt />
        <p class="color-g">
          获得：
          {{ userMoney }}
          <img src="@/assets/images/bigkill/m.png" alt />
        </p>
      </div>
      <div class="loss" v-show="isLoss">
        <img src="@/assets/images/bigkill/loss.png" alt />
        <p class="color-g">
          获得：
          {{ getMoney }}
          <img src="@/assets/images/bigkill/m.png" alt />
        </p>
      </div>
      <div class="btn color-white" @click="btn">确定</div>
    </div>
    <div v-show="isCopies" class="copies color-white">
      <div class="title t-c">是否购买门票</div>
      <!-- <input
        type="text"
        placeholder="请输入份数"
        oninput="value=value.replace(/[^\d]/g,'')"
        v-model="copies"
      />-->
      <div class="but" @click="Copies">确定</div>
      <div class="close" @click="(isCopies = false), (isbg = false)"></div>
    </div>
    <div class="left">
      <div class="top color-white" v-if="isStart" ref="time">
        <div class="text">{{ time }}秒后杀手出现,{{ type }}</div>
      </div>
      <div class="top color-white" v-else>
        <div class="text">等待中，{{ type }}</div>
      </div>
      <div>
        <div class="main color-white">
          <p>
            游戏规则：
            玩家进入大逃杀模式后，可选择自己躲避的房间，倒计时60秒结束后，杀手进入猎杀时刻，选择某个房间后，房间内的人躲避失败，其他玩家躲避成功
          </p>
        </div>
        <div class="bottom">
          <!-- <div class="phb" @click="phb"></div> -->
          <div class="histroy" @click="histroy"></div>
        </div>
      </div>
    </div>
    <div class="right">
      <div class="close" @click="back"></div>
      <div class="fh one" @click="jion('one')">
        <img src="@/assets/images/bigkill/roombg.png" alt />
        <span class="roomname">卧室</span>
        <img width="15px" src="@/assets/images/bigkill/m.png" alt />
        {{ ym }}
      </div>
      <div class="fh two" @click="jion('two')">
        <img src="@/assets/images/bigkill/roombg.png" alt />
        <span class="roomname">客厅</span>
        <img width="15px" src="@/assets/images/bigkill/m.png" alt />
        {{ em }}
      </div>
      <div class="fh three" @click="jion('three')">
        <img src="@/assets/images/bigkill/roombg.png" alt />
        <span class="roomname">厨房</span>
        <img width="15px" src="@/assets/images/bigkill/m.png" alt />
        {{ sm }}
      </div>
      <div class="fh four" @click="jion('four')">
        <img src="@/assets/images/bigkill/roombg.png" alt />
        <span class="roomname">天台</span>
        <img width="15px" src="@/assets/images/bigkill/m.png" alt />
        {{ sim }}
      </div>
      <div class="fh five" @click="jion('five')">
        <img src="@/assets/images/bigkill/roombg.png" alt />
        <span class="roomname">卫生间</span>
        <img width="15px" src="@/assets/images/bigkill/m.png" alt />
        {{ wm }}
      </div>
      <div class="fh six" @click="jion('six')">
        <img src="@/assets/images/bigkill/roombg.png" alt />
        <span class="roomname">书房</span>
        <img width="15px" src="@/assets/images/bigkill/m.png" alt />
        {{ lm }}
      </div>
      <!-- <div >{{item}}--{{index}}</div> -->
      <div class="playersbox">
        <div class="yihao">
          <div
            v-for="item in yrenshu"
            :key="item.id"
            class="per"
            ref="img1"
          ></div>
        </div>
        <div class="erhao">
          <div
            v-for="item in erenshu"
            :key="item.id"
            class="per"
            ref="img2"
          ></div>
        </div>
        <div class="sanhao">
          <div
            v-for="item in srenshu"
            :key="item.id"
            class="per"
            ref="img3"
          ></div>
        </div>
        <div class="sihao">
          <div
            v-for="item in sirenshu"
            :key="item.id"
            class="per"
            ref="img4"
          ></div>
        </div>
        <div class="wuhao">
          <div
            v-for="item in wrenshu"
            :key="item.id"
            class="per"
            ref="img5"
          ></div>
        </div>
        <div class="liuhao">
          <div
            v-for="item in lrenshu"
            :key="item.id"
            class="per"
            ref="img6"
          ></div>
        </div>
      </div>
      <div class="killerbox">
        <div class="killer" ref="kill"></div>
      </div>
    </div>
    <div class="bg" v-show="isbg"></div>
  </div>
</template>

<script>
import {
  getbigkillroom,
  getbigkilHistroy,
  getUserInfo,
  updateUserInfo,
} from "../../../api/index";

export default {
  data() {
    return {
      firstroom: "", //电脑端房间路径
      secondroom: "",
      thirdroom: "",
      fourthroom: "",
      fivethroom: "",
      sixthroom: "",
      Mfirstroom: "", //手机端房间路径
      Msecondroom: "",
      Mthirdroom: "",
      Mfourthroom: "",
      Mfivethroom: "",
      Msixthroom: "",
      Runx: "", //玩家左右移动
      Runy: "", //玩家上下移动
      player1: [], //玩家列表
      player2: [], //玩家列表
      player3: [], //玩家列表
      player4: [], //玩家列表
      player5: [], //玩家列表
      player6: [], //玩家列表
      killer: "",
      time: 0, //初始化倒计时
      isStart: false,
      isShow: false,
      isPhone: false,
      rankId: "", //模式id
      roomid: "", //房间id
      copies: 1, //选择份数
      isCopies: false,
      jionroom: "", //选择加入的房间
      timeoutObj: "", //计时器名称
      uid: "", //玩家id
      users: {}, //玩家
      yrenshu: [], //1人数
      erenshu: [], //2人数
      srenshu: [], //3人数
      sirenshu: [], //4人数
      wrenshu: [], //5人数
      lrenshu: [], //6人数
      ym: 0, //1号房金币总数
      em: 0, //2号房金币总数
      sm: 0, //3号房金币总数
      sim: 0, //4号房金币总数
      wm: 0, //5号房金币总数
      lm: 0, //6号房金币总数
      arr: [],
      data: "",
      killerId: 0,
      isResult: false,
      isWin: false,
      isLoss: false,
      getMoney: 0,
      userMoney: 0,
      isbg: false,
      historicalRecord: [],
      myhistroy: [],
      rid: "",
      type: "",
    };
  },
  created() {
    this.$bus.$on("roominfo", (res) => {
      sessionStorage.setItem("usersdate", JSON.stringify(res));
    });
    //；路由传参
    this.rankId = this.$route.params.id;
    if (this.rankId == 1) {
      this.type = "初级";
    } else if (this.rankId == 2) {
      this.type = "中级";
    } else if (this.rankId == 3) {
      this.type = "高级";
    }
    //获取玩家dom结构
    this.killer = this.$refs.kill;
    if (sessionStorage.getItem("usersdate")) {
      let res = JSON.parse(sessionStorage.getItem("usersdate"));
      for (const key in res) {
        if (key == 1) {
          this.yrenshu = res[key];
          for (const item of res[key]) {
            //渲染房间总金额
            this.ym += item.ticketPriceTotal;
          }
        } else if (key == 2) {
          this.erenshu = res[key];
          for (const item of res[key]) {
            //渲染房间总金额
            this.em += item.ticketPriceTotal;
          }
        } else if (key == 3) {
          this.srenshu = res[key];
          for (const item of res[key]) {
            //渲染房间总金额
            this.sm += item.ticketPriceTotal;
          }
        } else if (key == 4) {
          this.sirenshu = res[key];
          for (const item of res[key]) {
            //渲染房间总金额
            this.sim += item.ticketPriceTotal;
          }
        } else if (key == 5) {
          this.wrenshu = res[key];
          for (const item of res[key]) {
            //渲染房间总金额
            this.wm += item.ticketPriceTotal;
          }
        } else if (key == 6) {
          this.lrenshu = res[key];
          for (const item of res[key]) {
            //渲染房间总金额
            this.lm += item.ticketPriceTotal;
          }
        } else if (key == 7) {
          this.yrenshu = res[key];
          for (const item of res[key]) {
            //渲染房间总金额
            this.ym += item.ticketPriceTotal;
          }
        } else if (key == 8) {
          this.erenshu = res[key];
          for (const item of res[key]) {
            //渲染房间总金额
            this.em += item.ticketPriceTotal;
          }
        } else if (key == 9) {
          this.srenshu = res[key];
          for (const item of res[key]) {
            //渲染房间总金额
            this.sm += item.ticketPriceTotal;
          }
        } else if (key == 10) {
          this.sirenshu = res[key];
          for (const item of res[key]) {
            //渲染房间总金额
            this.sim += item.ticketPriceTotal;
          }
        } else if (key == 11) {
          this.wrenshu = res[key];
          for (const item of res[key]) {
            //渲染房间总金额
            this.wm += item.ticketPriceTotal;
          }
        } else if (key == 12) {
          this.lrenshu = res[key];
          for (const item of res[key]) {
            //渲染房间总金额
            this.lm += item.ticketPriceTotal;
          }
        } else if (key == 13) {
          this.yrenshu = res[key];
          for (const item of res[key]) {
            //渲染房间总金额
            this.ym += item.ticketPriceTotal;
          }
        } else if (key == 14) {
          this.erenshu = res[key];
          for (const item of res[key]) {
            //渲染房间总金额
            this.em += item.ticketPriceTotal;
          }
        } else if (key == 15) {
          this.srenshu = res[key];
          for (const item of res[key]) {
            //渲染房间总金额
            this.sm += item.ticketPriceTotal;
          }
        } else if (key == 16) {
          this.sirenshu = res[key];
          for (const item of res[key]) {
            //渲染房间总金额
            this.sim += item.ticketPriceTotal;
          }
        } else if (key == 17) {
          this.wrenshu = res[key];
          for (const item of res[key]) {
            //渲染房间总金额
            this.wm += item.ticketPriceTotal;
          }
        } else if (key == 18) {
          this.lrenshu = res[key];
          for (const item of res[key]) {
            //渲染房间总金额
            this.lm += item.ticketPriceTotal;
          }
        }
      }
    }
  },
  mounted() {
    //获取用户id
    getUserInfo().then((res) => {
      this.uid = res.data.user.id;
      this.initWebSocket();
    });

    if (window.innerWidth < 550) {
      this.isPhone = true;
    }
    //玩家左右移动
    this.Runx = function Runx(box, h1, h2) {
      //封装函数
      var myInter = setInterval(function () {
        var offsetLeft = box.offsetLeft;
        var num = h1; //每次都移动h1个像素
        var target = h2; //一共移动的距离
        if (offsetLeft == target) {
          //通过if来判断，到达了设定距离，就会删除间隔函数
          clearInterval(myInter);
        } else {
          box.style.left = offsetLeft + num + "px"; //没有达到距离，一直赋值给‘盒子’左边距
        }
      }, 25);
    };
    //玩家上下移动
    this.Runy = function Runy(box, h1, h2) {
      //封装函数
      var myInter = setInterval(function () {
        var offsetTop = box.offsetTop;
        var num = h1; //每次都移动h1个像素
        var target = h2; //一共移动的距离
        if (offsetTop == target) {
          //通过if来判断，到达了设定距离，就会删除间隔函数
          clearInterval(myInter);
        } else {
          box.style.top = offsetTop + num + "px"; //没有达到距离，一直赋值给‘盒子’左边距
        }
      }, 25);
    };
    //一号房间
    this.firstroom = function (player, x, X, y, Y, x1, X1) {
      this.Runx(player, x, X);
      setTimeout(() => {
        this.Runy(player, y, Y);
      }, 160);
      setTimeout(() => {
        this.Runx(player, x1, X1);
      }, 1500);
    };
    this.secondroom = function (player, x, X, y, Y, x1, X1) {
      this.Runx(player, x, X);
      setTimeout(() => {
        this.Runy(player, y, Y);
      }, 160);
      setTimeout(() => {
        this.Runx(player, x1, X1);
      }, 1500);
    };
    this.thirdroom = function (player, x, X, y, Y, x1, X1) {
      this.Runx(player, x, X);
      setTimeout(() => {
        this.Runy(player, y, Y);
      }, 160);
      setTimeout(() => {
        this.Runx(player, x1, X1);
      }, 1000);
    };
    this.fourthroom = function (player, x, X, y, Y, x1, X1) {
      this.Runx(player, x, X);
      setTimeout(() => {
        this.Runy(player, y, Y);
      }, 160);
      setTimeout(() => {
        this.Runx(player, x1, X1);
      }, 800);
    };
    this.fivethroom = function (player, x, X, y, Y, x1, X1) {
      this.Runx(player, x, X);
      setTimeout(() => {
        this.Runy(player, y, Y);
      }, 160);
      setTimeout(() => {
        this.Runx(player, x1, X1);
      }, 800);
    };
    this.sixthroom = function (player, x, X, y, Y, x1, X1) {
      this.Runx(player, x, X);
      setTimeout(() => {
        this.Runy(player, y, Y);
      }, 160);
      setTimeout(() => {
        this.Runx(player, x1, X1);
      }, 800);
    };
  },
  beforeDestroy() {
    this.websock.close(); //离开路由之后断开websocket连接
    clearTimeout(this.timeoutObj); //离开清除 timeout
  },
  methods: {
    back() {
      this.$router.go(-1);
    },
    btn() {
      this.isResult = false;
      sessionStorage.removeItem("usersdate");
      this.$bus.$emit("openbox", 1);
      this.$router.go(0);
    },
    initWebSocket() {
      let wsurl = `ws://api.u8csgo.com/websocket/${this.uid}/${this.rankId}`;
      // let wsurl = `ws://192.168.3.25:8080/api/battleRoyale/websocket/${this.uid}/${this.rankId}`;
      this.websock = new WebSocket(wsurl);
      this.websock.onopen = this.websocketonopen; //连接成功
      this.websock.onmessage = this.websocketonmessage; //广播成功
      this.websock.onerror = this.websocketonerror; //连接断开，失败
      this.websock.onclose = this.websocketclose; //连接关闭
    }, //初始化weosocket
    websocketonopen() {
      // console.log("连接成功");
      this.websock.send("ping");
    }, //连接成功
    websocketonerror() {
      // console.log("连接失败");
    }, //连接失败
    websocketclose() {
      // console.log("断开连接");
      clearTimeout(this.timeoutObj);
    }, //各种问题导致的 连接关闭
    websocketonmessage(data) {
      let aa = data.data;
      if (aa !== "pong") {
        let res = JSON.parse(aa);
        let daojishi = "countdown" in res;
        if (daojishi) {
          this.isStart = true;
          this.time = res.countdown;
        } else {
          this.isStart = false;
        }
        let users = "roomNumGrouping" in res;
        if (users) {
          sessionStorage.setItem(
            "usersdate",
            JSON.stringify(res.roomNumGrouping)
          );
          for (const key in res.roomNumGrouping) {
            if (key == 1) {
              // console.log(res.roomNumGrouping[key]);
              this.yrenshu = res.roomNumGrouping[key];
              let money = this.yrenshu.reduce(
                (a, b) => a + b.ticketPriceTotal,
                0
              );
              this.ym = money;
            } else if (key == 2) {
              this.erenshu = res.roomNumGrouping[key];
              let money = this.erenshu.reduce(
                (a, b) => a + b.ticketPriceTotal,
                0
              );
              this.em = money;
            } else if (key == 3) {
              this.srenshu = res.roomNumGrouping[key];
              let money = this.srenshu.reduce(
                (a, b) => a + b.ticketPriceTotal,
                0
              );
              this.sm = money;
            } else if (key == 4) {
              this.sirenshu = res.roomNumGrouping[key];
              let money = this.sirenshu.reduce(
                (a, b) => a + b.ticketPriceTotal,
                0
              );
              this.sim = money;
            } else if (key == 5) {
              this.wrenshu = res.roomNumGrouping[key];
              let money = this.wrenshu.reduce(
                (a, b) => a + b.ticketPriceTotal,
                0
              );
              this.wm = money;
            } else if (key == 6) {
              this.lrenshu = res.roomNumGrouping[key];
              let money = this.lrenshu.reduce(
                (a, b) => a + b.ticketPriceTotal,
                0
              );
              this.lm = money;
            } else if (key == 7) {
              this.yrenshu = res.roomNumGrouping[key];
              let money = this.yrenshu.reduce(
                (a, b) => a + b.ticketPriceTotal,
                0
              );
              this.ym = money;
            } else if (key == 8) {
              this.erenshu = res.roomNumGrouping[key];
              let money = this.erenshu.reduce(
                (a, b) => a + b.ticketPriceTotal,
                0
              );
              this.em = money;
            } else if (key == 9) {
              this.srenshu = res.roomNumGrouping[key];
              let money = this.srenshu.reduce(
                (a, b) => a + b.ticketPriceTotal,
                0
              );
              this.sm = money;
            } else if (key == 10) {
              this.sirenshu = res.roomNumGrouping[key];
              let money = this.sirenshu.reduce(
                (a, b) => a + b.ticketPriceTotal,
                0
              );
              this.sim = money;
            } else if (key == 11) {
              this.wrenshu = res.roomNumGrouping[key];
              let money = this.wrenshu.reduce(
                (a, b) => a + b.ticketPriceTotal,
                0
              );
              this.wm = money;
            } else if (key == 12) {
              this.lrenshu = res.roomNumGrouping[key];
              let money = this.lrenshu.reduce(
                (a, b) => a + b.ticketPriceTotal,
                0
              );
              this.lm = money;
            } else if (key == 13) {
              this.yrenshu = res.roomNumGrouping[key];
              let money = this.yrenshu.reduce(
                (a, b) => a + b.ticketPriceTotal,
                0
              );
              this.ym = money;
            } else if (key == 14) {
              this.erenshu = res.roomNumGrouping[key];
              let money = this.erenshu.reduce(
                (a, b) => a + b.ticketPriceTotal,
                0
              );
              this.em = money;
            } else if (key == 15) {
              this.srenshu = res.roomNumGrouping[key];
              let money = this.srenshu.reduce(
                (a, b) => a + b.ticketPriceTotal,
                0
              );
              this.sm = money;
            } else if (key == 16) {
              this.sirenshu = res.roomNumGrouping[key];
              let money = this.sirenshu.reduce(
                (a, b) => a + b.ticketPriceTotal,
                0
              );
              this.sim = money;
            } else if (key == 17) {
              this.wrenshu = res.roomNumGrouping[key];
              let money = this.wrenshu.reduce(
                (a, b) => a + b.ticketPriceTotal,
                0
              );
              this.wm = money;
            } else if (key == 18) {
              this.lrenshu = res.roomNumGrouping[key];
              let money = this.lrenshu.reduce(
                (a, b) => a + b.ticketPriceTotal,
                0
              );
              this.lm = money;
            }
          }
        }
        let result = "result" in res;
        if (result) {
          sessionStorage.removeItem("usersdate");
          updateUserInfo().then(() => {});
          this.$bus.$emit("openbox", 1);
          if (res.result.result == "1") {
            setTimeout(() => {
              this.isResult = true;
              this.isWin = true;
              this.isbg = true;
            }, 2000);
            this.getMoney = res.result.coinChange;
            this.userMoney =
              res.result.coinChange + res.result.participationPrice;
          } else if (res.result.result == "0") {
            setTimeout(() => {
              this.isResult = true;
              this.isLoss = true;
              this.isbg = true;
            }, 2000);
            this.getMoney = res.result.coinChange;
          }
        }
        if (window.innerWidth < 550) {
          //手机端
          this.killer = this.$refs.kill;
          let killid = "randomRoomId" in res;
          if (killid) {
            this.killerId = res.randomRoomId;
            sessionStorage.removeItem("usersdate");
            if (this.killerId == 1) {
              this.firstroom(this.killer, 5, 5, -5, -255, -5, -85);
            } else if (this.killerId == 2) {
              this.secondroom(this.killer, 5, 5, -5, -255, 5, 85);
            } else if (this.killerId == 3) {
              // console.log(this.killer);
              this.thirdroom(this.killer, 5, 5, -5, -185, 5, 95);
            } else if (this.killerId == 4) {
              this.fourthroom(this.killer, 5, 5, -5, -130, 5, 85);
            } else if (this.killerId == 5) {
              this.fivethroom(this.killer, 5, 5, -5, -130, -5, -85);
            } else if (this.killerId == 6) {
              this.sixthroom(this.killer, 5, 5, -5, -185, -5, -85);
            } else if (this.killerId == 7) {
              this.firstroom(this.killer, 5, 5, -5, -255, -5, -85);
            } else if (this.killerId == 8) {
              this.secondroom(this.killer, 5, 5, -5, -255, 5, 85);
            } else if (this.killerId == 9) {
              this.thirdroom(this.killer, 5, 5, -5, -185, 5, 95);
            } else if (this.killerId == 10) {
              this.fourthroom(this.killer, 5, 5, -5, -130, 5, 85);
            } else if (this.killerId == 11) {
              this.fivethroom(this.killer, 5, 5, -5, -130, -5, -85);
            } else if (this.killerId == 12) {
              this.sixthroom(this.killer, 5, 5, -5, -185, -5, -85);
            } else if (this.killerId == 13) {
              this.firstroom(this.killer, 5, 5, -5, -255, -5, -85);
            } else if (this.killerId == 14) {
              this.secondroom(this.killer, 5, 5, -5, -255, 5, 85);
            } else if (this.killerId == 15) {
              this.thirdroom(this.killer, 5, 5, -5, -185, 5, 95);
            } else if (this.killerId == 16) {
              this.fourthroom(this.killer, 5, 5, -5, -130, 5, 85);
            } else if (this.killerId == 17) {
              this.fivethroom(this.killer, 5, 5, -5, -130, -5, -85);
            } else if (this.killerId == 18) {
              this.sixthroom(this.killer, 5, 5, -5, -185, -5, -85);
            }
            setTimeout(() => {
              this.$router.go(0);
            }, 5500);
          }
        } else {
          //电脑端
          this.killer = this.$refs.kill;
          let killid = "randomRoomId" in res;
          if (killid) {
            sessionStorage.removeItem("usersdate");
            this.killerId = res.randomRoomId;
            if (this.killerId == 1) {
              this.firstroom(this.killer, 10, 150, -10, -510, -10, -60);
            } else if (this.killerId == 2) {
              this.secondroom(this.killer, 10, 150, -10, -510, 10, 310);
            } else if (this.killerId == 3) {
              // console.log(this.killer);
              this.thirdroom(this.killer, 10, 150, -10, -350, 10, 310);
            } else if (this.killerId == 4) {
              this.fourthroom(this.killer, 10, 150, -10, -230, 10, 310);
            } else if (this.killerId == 5) {
              this.fivethroom(this.killer, 10, 150, -10, -230, -10, -80);
            } else if (this.killerId == 6) {
              this.sixthroom(this.killer, 10, 150, -10, -350, -10, -80);
            } else if (this.killerId == 7) {
              this.firstroom(this.killer, 10, 150, -10, -510, -10, -60);
            } else if (this.killerId == 8) {
              this.secondroom(this.killer, 10, 150, -10, -510, 10, 310);
            } else if (this.killerId == 9) {
              this.thirdroom(this.killer, 10, 150, -10, -350, 10, 310);
            } else if (this.killerId == 10) {
              this.fourthroom(this.killer, 10, 150, -10, -230, 10, 310);
            } else if (this.killerId == 11) {
              this.fivethroom(this.killer, 10, 150, -10, -230, -10, -80);
            } else if (this.killerId == 12) {
              this.sixthroom(this.killer, 10, 150, -10, -350, -10, -80);
            } else if (this.killerId == 13) {
              this.firstroom(this.killer, 10, 150, -10, -510, -10, -60);
            } else if (this.killerId == 14) {
              this.secondroom(this.killer, 10, 150, -10, -510, 10, 310);
            } else if (this.killerId == 15) {
              this.thirdroom(this.killer, 10, 150, -10, -350, 10, 310);
            } else if (this.killerId == 16) {
              this.fourthroom(this.killer, 10, 150, -10, -230, 10, 310);
            } else if (this.killerId == 17) {
              this.fivethroom(this.killer, 10, 150, -10, -230, -10, -80);
            } else if (this.killerId == 18) {
              this.sixthroom(this.killer, 10, 150, -10, -350, -10, -80);
            }
            setTimeout(() => {
              this.$router.go(0);
            }, 5500);
          }
        }
      }
    }, //数据接收
    websocketsend() {
      // this.websock.send("ping");
      this.heatBeat();
    }, //数据发送
    Copies() {
      getbigkillroom(this.rankId, this.roomid, this.copies).then((res) => {
        if (res.data.code == 200) {
          this.$bus.$emit("openbox", 1);
          this.isCopies = false;
          this.isbg = false;
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    jion(room) {
      if (room == "one") {
        //手机端
        if (this.isPhone) {
          this.isCopies = true;
          this.isbg = true;
          //根据模式判断房间号
          if (this.rankId == 1) {
            //初级模式
            this.roomid = 1;
          } else if (this.rankId == 2) {
            //中级模式
            this.roomid = 7;
          } else if (this.rankId == 3) {
            //高级模式
            this.roomid = 13;
          }
          this.jionroom = room;
        } else {
          this.isCopies = true;
          this.isbg = true;
          //根据模式判断房间号
          if (this.rankId == 1) {
            //初级模式
            this.roomid = 1;
          } else if (this.rankId == 2) {
            //中级模式
            this.roomid = 7;
          } else if (this.rankId == 3) {
            //高级模式
            this.roomid = 13;
          }
          this.jionroom = room;
        }
      } else if (room == "two") {
        if (this.isPhone) {
          this.isbg = true;
          this.isCopies = true;
          //根据模式判断房间号
          if (this.rankId == 1) {
            this.roomid = 2;
          } else if (this.rankId == 2) {
            this.roomid = 8;
          } else if (this.rankId == 3) {
            this.roomid = 14;
          }
        } else {
          this.isbg = true;
          this.isCopies = true;
          //根据模式判断房间号
          if (this.rankId == 1) {
            this.roomid = 2;
          } else if (this.rankId == 2) {
            this.roomid = 8;
          } else if (this.rankId == 3) {
            this.roomid = 14;
          }
        }
      } else if (room == "three") {
        if (this.isPhone) {
          this.isbg = true;
          this.isCopies = true;
          //根据模式判断房间号
          if (this.rankId == 1) {
            this.roomid = 3;
          } else if (this.rankId == 2) {
            this.roomid = 9;
          } else if (this.rankId == 3) {
            this.roomid = 15;
          }
        } else {
          this.isbg = true;
          this.isCopies = true;
          //根据模式判断房间号
          if (this.rankId == 1) {
            this.roomid = 3;
          } else if (this.rankId == 2) {
            this.roomid = 9;
          } else if (this.rankId == 3) {
            this.roomid = 15;
          }
        }
      } else if (room == "four") {
        if (this.isPhone) {
          this.isCopies = true;
          this.isbg = true;

          //根据模式判断房间号
          if (this.rankId == 1) {
            this.roomid = 4;
          } else if (this.rankId == 2) {
            this.roomid = 10;
          } else if (this.rankId == 3) {
            this.roomid = 16;
          }
        } else {
          this.isCopies = true;
          this.isbg = true;

          //根据模式判断房间号
          if (this.rankId == 1) {
            this.roomid = 4;
          } else if (this.rankId == 2) {
            this.roomid = 10;
          } else if (this.rankId == 3) {
            this.roomid = 16;
          }
        }
      } else if (room == "five") {
        if (this.isPhone) {
          this.isCopies = true;
          this.isbg = true;

          //根据模式判断房间号
          if (this.rankId == 1) {
            this.roomid = 5;
          } else if (this.rankId == 2) {
            this.roomid = 11;
          } else if (this.rankId == 3) {
            this.roomid = 17;
          }
        } else {
          this.isCopies = true;
          this.isbg = true;

          //根据模式判断房间号
          if (this.rankId == 1) {
            this.roomid = 5;
          } else if (this.rankId == 2) {
            this.roomid = 11;
          } else if (this.rankId == 3) {
            this.roomid = 17;
          }
        }
      } else if (room == "six") {
        if (this.isPhone) {
          this.isbg = true;
          this.isCopies = true;
          //根据模式判断房间号
          if (this.rankId == 1) {
            this.roomid = 6;
          } else if (this.rankId == 2) {
            this.roomid = 12;
          } else if (this.rankId == 3) {
            this.roomid = 18;
          }
        } else {
          this.isbg = true;
          this.isCopies = true;
          //根据模式判断房间号
          if (this.rankId == 1) {
            this.roomid = 6;
          } else if (this.rankId == 2) {
            this.roomid = 12;
          } else if (this.rankId == 3) {
            this.roomid = 18;
          }
        }
      }
    },
    histroy() {
      getbigkilHistroy(this.rankId).then((res) => {
        // console.log(res);
        this.historicalRecord = res.data.data.historicalRecord;
        for (const item of this.historicalRecord) {
          if (item.roomId == 1) {
            item.roomId = "卧室";
          } else if (item.roomId == 2) {
            item.roomId = "客厅";
          } else if (item.roomId == 3) {
            item.roomId = "厨房";
          } else if (item.roomId == 4) {
            item.roomId = "天台";
          } else if (item.roomId == 5) {
            item.roomId = "卫生间";
          } else if (item.roomId == 6) {
            item.roomId = "书房";
          } else if (item.roomId == 7) {
            item.roomId = "卧室";
          } else if (item.roomId == 8) {
            item.roomId = "客厅";
          } else if (item.roomId == 9) {
            item.roomId = "厨房";
          } else if (item.roomId == 10) {
            item.roomId = "天台";
          } else if (item.roomId == 11) {
            item.roomId = "卫生间";
          } else if (item.roomId == 13) {
            item.roomId = "卧室";
          } else if (item.roomId == 14) {
            item.roomId = "客厅";
          } else if (item.roomId == 15) {
            item.roomId = "厨房";
          } else if (item.roomId == 16) {
            item.roomId = "天台";
          } else if (item.roomId == 17) {
            item.roomId = "卫生间";
          } else if (item.roomId == 18) {
            item.roomId = "书房";
          }
        }
        this.myhistroy = res.data.data.myJoinRecord;
        for (const item of this.myhistroy) {
          if (item.roomId == 1) {
            item.roomId = "卧室";
          } else if (item.roomId == 2) {
            item.roomId = "客厅";
          } else if (item.roomId == 3) {
            item.roomId = "厨房";
          } else if (item.roomId == 4) {
            item.roomId = "天台";
          } else if (item.roomId == 5) {
            item.roomId = "卫生间";
          } else if (item.roomId == 6) {
            item.roomId = "书房";
          } else if (item.roomId == 7) {
            item.roomId = "卧室";
          } else if (item.roomId == 8) {
            item.roomId = "客厅";
          } else if (item.roomId == 9) {
            item.roomId = "厨房";
          } else if (item.roomId == 10) {
            item.roomId = "天台";
          } else if (item.roomId == 11) {
            item.roomId = "卫生间";
          } else if (item.roomId == 12) {
            item.roomId = "书房";
          } else if (item.roomId == 13) {
            item.roomId = "卧室";
          } else if (item.roomId == 14) {
            item.roomId = "客厅";
          } else if (item.roomId == 15) {
            item.roomId = "厨房";
          } else if (item.roomId == 16) {
            item.roomId = "天台";
          } else if (item.roomId == 17) {
            item.roomId = "卫生间";
          } else if (item.roomId == 18) {
            item.roomId = "书房";
          }
        }
        for (const item of this.myhistroy) {
          if (item.killRoom == 1) {
            item.killRoom = "卧室";
          } else if (item.killRoom == 2) {
            item.killRoom = "客厅";
          } else if (item.killRoom == 3) {
            item.killRoom = "厨房";
          } else if (item.killRoom == 4) {
            item.killRoom = "天台";
          } else if (item.killRoom == 5) {
            item.killRoom = "卫生间";
          } else if (item.killRoom == 6) {
            item.killRoom = "书房";
          } else if (item.killRoom == 7) {
            item.killRoom = "卧室";
          } else if (item.killRoom == 8) {
            item.killRoom = "客厅";
          } else if (item.killRoom == 9) {
            item.killRoom = "厨房";
          } else if (item.killRoom == 10) {
            item.killRoom = "天台";
          } else if (item.killRoom == 11) {
            item.killRoom = "卫生间";
          } else if (item.killRoom == 12) {
            item.killRoom = "书房";
          } else if (item.killRoom == 13) {
            item.killRoom = "卧室";
          } else if (item.killRoom == 14) {
            item.killRoom = "客厅";
          } else if (item.killRoom == 15) {
            item.killRoom = "厨房";
          } else if (item.killRoom == 16) {
            item.killRoom = "天台";
          } else if (item.killRoom == 17) {
            item.killRoom = "卫生间";
          } else if (item.killRoom == 18) {
            item.killRoom = "书房";
          }
        }
      });
      this.isShow = true;
    },
    phb() {},
    close() {
      this.isShow = false;
    },
  },
};
</script>

<style scoped lang="scss">
.nav {
  width: 1200px;
  margin: 10px auto;
  height: 1000px;
  display: flex;
  justify-content: space-between;
  //弹窗
  .result {
    position: fixed;
    top: 10%;
    left: 50%;
    transform: translateX(-50%);
    z-index: 99;
    width: auto;
    height: auto;
    margin: auto;
    p {
      font-size: 33px;
      text-align: center;
    }
    .btn {
      font-size: 30px;
      text-align: center;
    }
  }
  .copies {
    width: 292px;
    height: 187px;
    background: url("~@/assets/images/bigkill/pass.png");
    background-size: 100% 100%;
    position: fixed;
    top: 10%;
    left: 50%;
    transform: translateX(-50%);
    z-index: 99;
    padding: 50px;
    box-sizing: border-box;
    .title {
      font-size: 25px;
    }
    input {
      outline: none;
      color: #fff;
      text-align: center;
      height: 30px;
      background-color: rgba(0, 0, 0, 0.24);
      border: none;
    }
    .but {
      width: 120px;
      height: 35px;
      margin: 20px auto;
      background: url("~@/assets/roll/btn215.png");
      background-size: 100% 100%;
      line-height: 35px;
      text-align: center;
    }
    .close {
      width: 44px;
      height: 44px;
      background: url("~@/assets/images/bigkill/close.png");
      background-size: 100% 100%;
      position: absolute;
      top: 0;
      right: 0;
    }
  }
  .bg {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 90;
    background-color: rgba(0, 0, 3, 0.868);
  }
  .killlog {
    width: 1200px;
    height: 580px;
    background: url("~@/assets/images/bigkill/log.png");
    background-size: 100% 100%;
    position: absolute;
    top: 30%;
    left: 50%;
    z-index: 99;
    transform: translateX(-50%);
    img {
      position: absolute;
      top: 40px;
      right: 66px;
    }
    .kill {
      width: 80%;
      margin: 30px auto;
      .top {
        width: 230px;
        height: 58px;
        margin: auto;
        background: url("~@/assets/images/bigkill/killerlog.png");
        background-size: 100% 100%;
      }
      .main .item {
        margin-top: 5px;
        width: 88px;
        height: 38px;
        background: url("~@/assets/images/bigkill/1.png");
        background-size: 100% 100%;
        text-align: center;
        line-height: 38px;
        .text {
          width: 88px;
          margin: -5px auto;
        }
      }
    }
    .mylog {
      width: 80%;
      margin: 50px auto;
      .top {
        width: 230px;
        height: 58px;
        margin: auto;
        background: url("~@/assets/images/bigkill/mylog.png");
        background-size: 100% 100%;
      }
      .main {
        .item {
          padding: 33px 33px;
          box-sizing: border-box;
          width: 280px;
          height: 210px;
          background: url("~@/assets/images/bigkill/myitem.png");
          background-size: 100% 100%;
          .one,
          .two,
          .three {
            margin: 20px 0;
          }
        }
      }
    }
  }
  .left {
    width: 350px;
    height: 100%;
    .top {
      width: 350px;
      height: 85px;
      background: url("~@/assets/images/bigkill/jiankong.png");
      background-size: 100% 100%;
      line-height: 115px;
      font-size: 25px;
      text-align: center;
      color: gold;
      .text {
        width: 85%;
        margin-left: 30px;
      }
    }
    .main {
      margin: 60px 0 0 0;
      width: 292px;
      height: 187px;
      background: url("~@/assets/images/bigkill/guize.png");
      background-size: 100% 100%;
      padding: 25px;
      box-sizing: border-box;
    }
    .bottom {
      width: 190px;
      height: 160px;
      margin-top: 285px;
      .phb {
        width: 190px;
        height: 70px;
        background: url("~@/assets/images/bigkill/phb.png");
        background-size: 100% 100%;
        margin-bottom: 10px;
      }
      .histroy {
        width: 190px;
        height: 70px;
        background: url("~@/assets/images/bigkill/histroy.png");
        background-size: 100% 100%;
      }
    }
  }
  .right {
    width: 720px;
    height: 715px;
    background: url("~@/assets/images/bigkill/room.png");
    background-size: 100% 100%;
    position: relative;
    position: relative;
    .close {
      width: 50px;
      height: 50px;
      position: absolute;
      top: 10%;
      right: -10%;
      background: url("~@/assets/images/bigkill/close.png");
      background-size: 100% 100%;
    }
    .fh {
      .roomname {
        position: absolute;
        width: 50px;
        top: 40px;
        left: 60px;
      }
    }
    .one,
    .two,
    .three,
    .four,
    .five,
    .six {
      position: absolute;
      color: #fff;
      z-index: 70;
    }
    .one {
      top: 0;
    }
    .two {
      top: 0;
      right: 0;
    }
    .three {
      top: 247px;
      right: 0px;
    }
    .four {
      bottom: 145px;
      right: 0px;
    }
    .five {
      bottom: 145px;
    }
    .six {
      top: 247px;
    }
    .playersbox {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0px;
      left: 0;

      .yihao {
        width: 200px;
        height: 200px;
        position: absolute;
        top: 75px;
        left: 53px;
      }
      .erhao {
        width: 200px;
        height: 200px;
        position: absolute;
        top: 75px;
        right: 53px;
      }
      .sanhao {
        width: 200px;
        height: 200px;
        position: absolute;
        top: 260px;
        right: 53px;
      }
      .sihao {
        width: 200px;
        height: 200px;
        position: absolute;
        bottom: 30px;
        right: 53px;
      }
      .wuhao {
        width: 200px;
        height: 200px;
        position: absolute;
        bottom: 30px;
        left: 53px;
      }
      .liuhao {
        width: 200px;
        height: 200px;
        position: absolute;
        top: 260px;
        left: 53px;
      }
      .per {
        position: absolute;
        width: 50px;
        height: 50px;
        background: url("~@/assets/images/bigkill/per.gif");
        background-size: 100% 100%;
        top: 30%;
      }
    }

    .killerbox {
      width: 350px;
      height: 100px;
      position: absolute;
      bottom: -83px;
      left: 182px;

      .killer {
        position: absolute;
        width: 60px;
        height: 70px;
        background: url("~@/assets/images/bigkill/killer.gif");
        background-size: 100% 100%;
      }
    }
  }
}
@media (max-width: 550px) {
  .nav {
    width: 100%;
    margin: 10px auto;
    height: 600px;
    display: block;

    .killlog {
      width: 100%;
      height: 580px;
      background: url("~@/assets/images/bigkill/log-m.png");
      background-size: 100% 100%;
      position: absolute;
      top: 10%;
      left: 0%;
      z-index: 99;
      transform: translateX(0);
      img {
        position: absolute;
        top: 20px;
        right: 24px;
      }
      .kill {
        width: 100%;
        margin: 20px auto;
        .top {
          width: 230px;
          height: 58px;
          margin: auto;
          background: url("~@/assets/images/bigkill/killerlog.png");
          background-size: 100% 100%;
        }
        .main {
          flex-wrap: wrap;
          height: 235px;
          .item {
            width: 88px;
            height: 38px;
            background: url("~@/assets/images/bigkill/1.png");
            background-size: 100% 100%;
            .text {
              width: 88px;
              margin: -5px auto;
            }
          }
        }
      }
      .mylog {
        width: 100%;
        margin: 20px auto;
        .top {
          width: 230px;
          height: 58px;
          margin: auto;
          background: url("~@/assets/images/bigkill/mylog.png");
          background-size: 100% 100%;
        }
        .main {
          overflow: auto;
          flex-wrap: wrap;
          height: 173px;

          .item {
            background: url("~@/assets/images/bigkill/myitem.png");
            padding: 26px;
            box-sizing: border-box;
            width: 190px;
            height: 200px;
            background-size: 100% 100%;
            font-size: 12px;
            .one,
            .two,
            .three {
              margin: 20px 0;
            }
          }
        }
      }
    }
    .left {
      width: 100%;
      height: auto;

      .top {
        width: 176px;
        height: 42px;
        background: url("~@/assets/images/bigkill/jiankong.png");
        background-size: 100% 100%;
        line-height: 65px;
        font-size: 13px;
        text-align: center;
        color: gold;
        font-family: auto;
      }
      div {
        display: flex;
        justify-content: space-around;
        .main {
          margin: 30px 0 0 0;
          width: 262px;
          height: auto;
          padding: 0;
          background: none;
          p {
            font-size: 12px;
            font-family: auto;
            color: #ccc;
          }
        }
        .bottom {
          width: auto;
          height: auto;
          margin: 35px 0;
          display: block;
          .phb {
            width: 190px;
            height: 70px;
            background: url("~@/assets/images/bigkill/phb.png");
            background-size: 100% 100%;
            margin-bottom: 10px;
          }
          .histroy {
            width: 110px;
            height: 45px;
            background: url("~@/assets/images/bigkill/histroy.png");
            background-size: 100% 100%;
          }
        }
      }
    }
    .right {
      width: 100%;
      height: 380px;
      background: url("~@/assets/images/bigkill/room-m.png");
      background-size: 100% 100%;
      position: relative;
      .close {
        width: 40px;
        height: 40px;
        position: absolute;
        top: -40%;
        right: 3%;
        background: url("~@/assets/images/bigkill/close.png");
        background-size: 100% 100%;
      }
      .fh {
        img:first-child {
          width: 110px;
          height: 51px;
        }
        .roomname {
          width: 50px;
          top: 25px;
          left: 30px;
        }
      }
      .one,
      .two,
      .three,
      .four,
      .five,
      .six,
      span {
        position: absolute;
        color: #fff;
      }
      .one {
        top: 0;
      }
      .two {
        top: 0;
        right: 0;
      }
      .three {
        top: 118px;
        right: 0px;
      }
      .four {
        bottom: 118px;
        right: 0px;
      }
      .five {
        bottom: 115px;
      }
      .six {
        top: 123px;
      }
      .playersbox {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0px;
        left: 0;

        .yihao {
          width: 80px;
          height: 80px;
          position: absolute;
          top: 50px;
          left: 35px;
        }
        .erhao {
          width: 80px;
          height: 80px;
          position: absolute;
          top: 50px;
          right: 35px;
        }
        .sanhao {
          width: 80px;
          height: 80px;
          position: absolute;
          top: 150px;
          right: 35px;
        }
        .sihao {
          width: 80px;
          height: 80px;

          position: absolute;
          top: 265px;
          right: 35px;
        }
        .wuhao {
          width: 80px;
          height: 80px;

          position: absolute;
          bottom: 30px;
          left: 35px;
        }
        .liuhao {
          width: 80px;
          height: 80px;

          position: absolute;
          top: 160px;
          left: 35px;
        }
        .per {
          position: absolute;
          width: 16px;
          height: 33px;
          background: url("~@/assets/images/bigkill/per.png");
          background-size: 100% 100%;
          top: 30%;
        }
      }

      .killerbox {
        width: auto;
        height: 100px;
        position: absolute;
        bottom: -83px;
        left: 182px;

        .killer {
          position: absolute;
          width: 40px;
          height: 50px;
          background: url("~@/assets/images/bigkill/killer.gif");
          background-size: 100% 100%;
        }
      }
    }
  }
}
</style>
